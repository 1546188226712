/* eslint-disable max-len */
const HEBREW = {
    LOADING: 'טוען...',
    SUBMIT: 'אישור',
    REMOVE: 'הסרה',
    ADD: 'הוספה',
    CLOSE: 'סגירה',
    SERVER_ERROR: 'שגיאה אירעה בשרת',
    SOMETHING_WENT_WRONG: 'משהו השתבש, אנא נסה שנית',
    LOADING_MESSAGE: 'טוען',
    GREETINGS: 'תתחדש',
    NO_INFO: 'העמוד לא קיים',
    MORE_MODELS: 'עוד דגמים',
    SUCCESS: 'SUCCESS',
    WITH_NO_EXTRA_COST: 'ללא תוספת תשלום',
    PAYMENT_DETAILS: 'פירוט התשלום',
    ORDER_DETAILS: 'פירוט ההזמנה',
    PROCEED_ONLINE_MY: 'My ',
    CONTACT_SALES_PERSON: 'לשיחה עם נציג מכירות',
    SOCIAL_SHARE_TOP: 'מה החברים והמשפחה חושבים?',
    SOCIAL_SHARE_BOTTOM: (carName) => `שתפו את ה- ${carName} שלכם`,
    SOCIAL_MESSAGE: (carName) => `פינקתי את עצמי עם ${carName} חדשה ושילמתי דרך האינטרנט! יותר קל מלהזמין חופשה`,
    SOCIAL_FACEBOOK: 'פייסבוק',
    SOCIAL_WHATSAPP: 'וואטסאפ',
    SOCIAL_MAIL: 'מייל',
    SOCIAL_MAIL_SUBJECT: 'הזמנתי רכב אונליין!',
    CONTINUE_TO_PAYMENT: 'המשך לתשלום',
    PAYMENT_TITLE: 'תשלום מקדמה',
    PAYMENT_MESSAGE_1: 'כמו שאמרנו, ',
    PAYMENT_MESSAGE_2: 'ישמשו כמקדמה בהתאם לחוזה ההזמנה על שמך, העסקה מאובטחת, אז אין לך מה לדאוג.',
    PAYMENT_MESSAGE_PRICE: ' 2,000 ₪ ',
    SUCCESS_TITLE: 'ההזמנה שלך התקבלה איזו התרגשות!',
    SUCCESS_MESSAGE:
    'נציג שלנו יעביר לך מייל עם כל מה שצריך להמשך התהליך ומסמכי ההזמנה ישלחו אלייך לחתימה דיגיטלית. מזל טוב!',
    SUCCESS_SERVICE_ID_MESSAGE: ' מזהה הזמנה לשירות ופניות',
    SUCCESS_LEGAL_MESSAGE_1_1:
    'לתשומת ליבך, אנו מתחילים בהכנת הרכב לקבלה. על כן, עומדים לרשותך 7 ימים לשלם את יתרת התשלום עבור הרכב.',
    SUCCESS_LEGAL_MESSAGE_1_2: 'קבלת הרכב תתבצע באולם התצוגה ממנו בחרת לאסוף את הרכב.',
    SUCCESSS_PDF_TEXT: 'להורדת המפרט',
    SUCCESS_LEGAL_MESSAGE_2: 'על פי מחירון חברה מספר 45, יוני 2019',
    ACCORDING_TO_COMPANY_PRICE_LIST_NUMBER: 'על פי מחירון חברה מספר',
    STARTING_FROM: 'החל מ',
    SUCCESS_WITH_TAX: 'המחירים כוללים מע"מ',
    ORDER_DETAILS_PRICE_TEXT: 'מחיר דגם',
    ORDER_DETAILS_COLOR_TEXT: 'צבע',
    AUDI_ORDER_DETAILS_COLOR_TEXT: 'צבע מרכב:',
    AUDI_ORDER_DETAILS_RIM_TEXT: 'חישוקים:',
    ORDER_DETAILS_INTERNAL_COLOR_TEXT: 'צבע פנים',
    AUDI_ORDER_DETAILS_INTERNAL_COLOR_TEXT: 'צבע פנים:',
    ORDER_DETAILS_EXTRAS_TEXT: 'תוספות',
    ORDER_DETAILS_TEGISTRY_TAX_TEXT: 'אגרת רישוי',
    ORDER_DETAILS_DEPOSIT_TEXT: 'מקדמה ששולמה',
    ORDER_DETAILS_TOTAL_PRICE_TEXT: 'סה"כ לתשלום',
    SUCCESS_SALES_CENTER_TEXT: 'ההצעה התקבלה באולם',
    SUCCESS_SALES_MAN_TEXT: 'על ידי',
    SUCCESS_SALES_CONTACT_TEXT: 'ניתן ליצור קשר בטלפון',
    SUCCESS_SALES_MAIL_TEXT: 'או במייל:',
    FAILURE_TEXT1: 'התרחשה',
    FAILURE_TEXT2: 'שגיאה',
    CAR_IMAGE_ALT: 'התמונה להמחשה בלבד',
    NO_CAR_IMAGE_ALT: 'לא קיימת תמונה להמחשה',
    CHAMPION_LOGO_ALT: "הלוגו של צ'מפיון מוטורוס",
    NO_CARS_TO_DISPLAY: 'הרכב לא זמין לרכישה ב Online',
    TO_FULL_SPECIFICATIONS: 'למפרט המלא',
    TO_SPECIFICATIONS: 'למפרט',
    SELECTION: 'בחירת',
    CHOOSE: 'בחר',
    CANCEL_SELECTION: 'בטל בחירה',
    PRICE: 'מחיר',
    IN_STOCK: 'קיים במלאי',
    SPECIFICATIONS_AND_TRIM_LEVEL: 'מפרט ורמות גימור',
    SPEAK_WITH_SALES_REPRESENTATIVE: 'לשיחה עם נציג מכירות',
    SPEAK_WITH_REPRESENTATIVE: 'לשיחה עם נציג',
    REVEAL_UNIQUE_ONLINE_PRICE: 'לחשיפת מחיר ייחודי ל-Online',
    INTERNAL_COLORS_DISCLAIMER: 'לתשומת לבך: צבעי הפנים המוצגים, תלויים בצבע החוץ שבחרת קודם לכן',
    MORE_INFO_REGARDING_THE_VEHICLE_YOU_CAN_FIND: ' לתשומת לבך : מידע נוסף על הרכב נמצא',
    CHOOSE_YOUR_MODEL_GROUP: 'בחר את ה %1% שלך',
    CHOOSE_YOUR_BRAND: 'בחירת ה%1% שלכם',
    CHOOSE_YOUR_BRAND_WITH_DASH: 'בחירת ה-%1% שלכם',
    PRICE_INCLUDES_VAR_AND_LICENSING_FEE: 'מחיר מחירון כולל מע"מ ואגרת רישוי',
    FILL_OUT_THE_DETAILS_AND_RECEIVE_A_CODE: 'מלאו את הפרטים, קבלו קוד לנייד ולאחר מכן תוכלו להמשיך לרכישת הרכב באתר במחיר שתקבלו ב-Online',
    INCLUDES_VAR_AND_LICENSING_FEE: 'כולל מע"מ ואגרת רישוי',
    NO_ADDITIONAL_PAYMENT: 'ללא תוספת תשלום',
    ADDITION_PAYMENT: 'תוספת תשלום',
    DETAILED_PRICE: 'פירוט המחיר',
    CAR_PRICE: 'מחיר הרכב',
    LICENSE_FEE: 'אגרת רישוי',
    TOTAL_DISCOUNT_FOR_ONLINE_PURCHASE: 'סה"כ ההנחה לרוכשים און ליין',
    POLLUTION_LEVEL: 'דרגת זיהום',
    MORE_INFORMATION: 'מידע נוסף',
    MORE_INFORMATION_ON_MANUFACTURER_VEHICLES: 'מידע נוסף על רכבי %1%',
    AIR_POLLUTION: 'זיהום אוויר',
    SAFETY_EQUIPMENT: 'אבזור בטיחותי',
    SAFETY: 'בטיחות',
    MAIN_CHARACTERISTIC: 'מאפיינים עיקריים',
    UNIQUE_TO_THIS_MODEL: 'ייחודי לדגם זה:',
    EQUIPMENT_PACKAGE: 'חבילת אבזור',
    EQUIPMENT_PACKAGE_CONTAINS: 'הכוללת',
    ACCEPT_PRIVACY_POLICY: 'מסכים למדיניות הפרטיות ומאשר שמירת הפרטים במאגר המידע',
    ACCEPT_USE_DETAILS: 'אני מאשר שימוש במידע בהתאם',
    FOR_PRIVACY_POLICY: 'למדיניות הפרטיות',
    OF_THE_COMPANY: 'של החברה',
    ACCEPT_ADVERTISING: 'הסכמה לקבלת דיוור שיווקי בהתאם',
    OF_CHAMPION_MOTORS: 'של צ\'מפיון מוטורס בע"מ',
    CONTINUE_TO: 'המשך ל',
    CONTINUE: 'המשך',
    TOOLTIP_PRICE_INNER_TEXT: 'לקבלת מחיר ה - Online, יש לסיים את תהליך בניית הרכב',
    FOR_FURTHER_INFORMATION_PLEASE_CONTACT: 'שימו לב! לשירותי טרייד אין וכל מידע נוסף אנא פנו למספר הטלפון %1%',
    YOU_HAVE_A_QUESTION: 'יש לך שאלה?',
    WE_HAVE_ANSWERS: 'יש לנו תשובות',
    HERE_ARE_SOME_ANSWERS_TO_COMMON_QUESTIONS: 'הנה כמה תשובות לשאלות שכיחות.',
    YOU_MUST_ACCEPT_PRIVACY_POLICY: 'עליך לאשר את מדיניות הפרטיות',
    PAGE_NOT_EXIST: 'העמוד לא קיים',
    WE_SEARCHED_ITS_NOT_HERE: 'חיפשנו, אבל זה לא פה',
    PICK_YOUR_VEHICLE: (brand) => `בחירת ה${brand} שלכם`,
    NO_PREVIEW_IS_AVAILABLE: 'אין תצוגה מקדימה',
    PREVIEW_OF_MODEL_DESCRIPTION: 'תצוגה מקדימה של %1%',
    NO_COLORS_ARE_AVAILABLE_TO_DISPLAY: 'אין צבעים זמינים להצגה',
    NO_ADDO_ONS_ARE_AVAILABLE_TO_DISPLAY: 'אין תוספות זמינות להצגה',
    NO_HOOPS_ARE_AVAILABLE_TO_DISPLAY: 'אין חישוקים זמינים להצגה',
    HAVE_YOU_RECEIVED_AN_SMS_CODE: 'קיבלת מאיתנו קוד ב-SMS?',
    A_MESSAGE_WITH_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO: 'הודעת טקסט עם קוד נשלחה למספר המסתיים ב- %1%',
    A_MESSAGE_WITH_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_VERIFICATION: 'הודעת טקסט עם קוד נשלחה למספר %1% לצורך אימות',
    INSERT_THE_CODE_YOU_HAVE_RECEIVED: 'נא להזין את הקוד שקיבלת',
    THE_CODE_I_HAVE_RECEIVED: 'הקוד שקיבלתי',
    LETS_CONTINUE: 'קדימה, בואו נמשיך',
    THIS_IS_NOT_MY_PHONE_NUMBER: 'זה לא מספר הנייד שלי',
    I_HAVE_NOT_RECEIVED_THE_CODE_PLEASE_SEND_AGAIN: 'לא קיבלתי קוד, שלחו לי שוב בבקשה',
    I_HAVE_NOT_RECEIVED_THE_CODE: 'לא קיבלתי את הקוד,',
    SEND_IT_AGAIN: 'שילחו לי אותו שוב',
    CONTACT_US_GET_MORE_DETAILS_TEXT: 'אם תרצו לדבר בצורה אישית יותר, השאירו פרטים ונחזור אליכם בהקדם. אתם יכולים גם לתפוס אותנו ב',
    OR_PHONE: 'או בטלפון:',
    CONNECT: 'התחבר',
    AUDI_CONNECT: 'התחברות',
    HERE: 'כאן',
    COMBINED: 'משולב',
    SOMETHING_IS_WRONG_WITH_YOUR_CODE_PLESAE_TRY_AGAIN: 'הקוד שהזנת אינו תקין - אנא נסה שנית',
    WE_GOT_IT: 'קיבלנו',
    WE_HAVE_SENT_YOUR_MESSAGE: 'שלחנו את ההודעה שלך',
    SHOULD_WE_RETURN_TO_CONTACT_US_PAGE: 'שנחזור לעמוד צור קשר',
    SEND_ME_BACK: 'תחזירו אותי',
    FOR_FURTHER_INFORMATION_AND_PURCHASE: 'למידע ולרכישה',
    HOOP_FOR_TRIM_LEVEL: 'חישוק עבור רמת הגימור',
    MODEL_AND_TRIM_LEVEL: 'מנוע ורמת גימור',
    OUT: 'חוץ',
    INTERNAL: 'פנים',
    HOOP: 'חישוק',
    LOCAL_ADDITIONS: 'תוספות מקומיות',
    LAST_DETAILS: 'פרטים אחרונים - והרכב בדרך אלייך',
    TIME_TO_SHARE_YOUR_PURCHASE: (brand) => `זה הזמן לשתף את ה${brand} שלך עם כולם`,
    I_PICKED_A_VEHICLE_ONLINE_WHATSAPP: (brand) => `*הרכבתי לעצמי רכב של ${brand} בOnline, לצפייה היכנסו לכאן*`,
    I_PICKED_A_VEHICLE_ONLINE: (brand) => `הרכבתי לעצמי רכב של ${brand} בOnline, לצפייה היכנסו לכאן`,
    I_PICKED_A_VEHICLE_ONLINE_TITLE: (brand) => `, לצפייה היכנסו לכאן Online הרכבתי לעצמי רכב של ${brand} ב`,
    I_PICKED_A_VEHICLE_ONLINE_EMAIL: (brand) => `בניתי רכב של ${brand} ב-Online`,
    INTRESTED_IN_A_NEW_VEHICLE: (brand) => `מעונינים ב${brand} חדשה ?`,

    // payment object titles
    PAYMENT_TITLE_MODEL: 'מחיר הדגם',
    PAYMENT_TITLE_SPECIFICATION: 'מפרט הרכב',
    PAYMENT_TITLE_EXTRAS: 'תוספות',
    PAYMENT_TITLE_LICENSE_FEE: 'אגרת רישוי',
    PAYMENT_TITLE_ONLINE_DISCOUNT: 'סה"כ הנחה לרוכשים Online',
    PAYMENT_TITLE_TOTAL: 'סה"כ לתשלום',
    PAYMENT_TITLE_TOTAL_INCLUDES_VAT: 'סה"כ לתשלום כולל מע"מ',
    PAYMENT_TOTAL_PRICE_LIST: 'סה"כ מחיר מחירון',
    AUDI_PAYMENT_TOTAL_PRICE_LIST: 'סה"כ מחיר',

    // errors
    ERRMSG_INVALID_PARAMS: 'נראה שהקישור לעמוד זה אינו תקין או פג תוקף',
    ERRMSG_UNKNOWN_ORDER: 'טעינת פרטי ההזמנה נכשלה',
    ERRMSG_UNSUPPORTED_BROWSER: 'גרסה זו של הדפדפן אינה נתמכת. אנא הכנסו מדפדפן חדש יותר',
    ERRMSG_INVALID_PAYMENT_URL: 'טעינת עמוד תשלום נכשלה',
    ERRMSG_INVALID_CALCULATION: 'נתוני הצעת המחיר לא תקינים (#1)',
    ERRMSG_LOW_PRICE: 'נתוני הצעת המחיר לא תקינים (#2)',
    ERRMSG_INVALID_OBJECT_DATA: 'טעינת העמוד לא הצליחה',
    ERRMSG_PROBLEM_IN_PURCHASE: 'אנחנו מזהים בעיה בתהליך רכישת הרכב שרצית. כרגע נשמח לעזור ב - %1% או באמצעות הצ\'אט באתר',
    ERRMSG_PROBLEM_IN_PURCHASE_ALREADY_PURCHASED: 'קיימת בעיה בתהליך רכישת הרכב, מכיוון שהמערכת זיהתה רכישה קודמת, לפרטים נוספים ניתן ליצור קשר במספר - %1% או באמצעות הצ\'אט באתר',
    ERRMSG_IN_REGISTRATION: 'אנחנו מזהים בעיה בתהליך ההרשמה. נשמח לעזור ב - %1% או באמצעות הצ\'אט באתר',
    ERRMSG_PROBLEM_IN_CONTACT: 'אנחנו מזהים בעיה בתהליך יצירת הקשר. כרגע נשמח לעזור ב - %1% או באמצעות הצ\'אט באתר',
    ERRMSG_YOU_HAVE_EXCEEDED_THE_NUMBER_OF_ATTEMPTS: 'עברת את מספר הניסיונות המותר, אנא עדכן מספר טלפון או שלח קוד חדש',
    HOW_DOES_IT_WORKS: 'איך זה עובד',
    TALK_TO_US: 'דברו איתנו',
    OUR_MODELS_ONLINE: 'הדגמים שלנו ב Online',
    TO_BRAND_WEBSITE: 'לאתר %1%',
    YOU_HAVE_REACHED_THE_MAX_NUMBER_OF_CHARACTERS: 'הגעת למספר התווים המקסימלי',
    YOU_DID_NOT_ENTER_ANYTHING: 'לא הזנת שום דבר',
    YOU_ARE_NOT_AUTHENTICATED: 'אתה לא מאומת',
    // footer
    MODELS: 'דגמים',
    ONLINE_PURCHASE: 'רכישה online',
    CAR_BOOKING_AGREEMENT_AND_CANCELLATION_POLICY: 'הסכם הזמנת רכב ותנאי ביטול',
    TERMS_OF_WARRANTY: 'תנאי אחריות',
    TERMS: 'תקנון',
    PRIVACY_POLICY: 'מדיניות פרטיות',
    QUESTIONS_AND_ANSWERS: 'שאלות ותשובות',
    CONTACT: 'צור קשר',
    ACCESSIBILITY_STATMENT: 'הצהרת נגישות',
    SPECIFICATION: 'מפרט',
    DISCLAIMER: 'כל הזכויות שמורות <<website>> המופעל על ידי חברת צ\'מפיון מוטורס בע"מ יבואן ישיר של רכבי <<brandName>> בישראל ומציעה את מגוון רכבי <<brandName>>',
    FOR_ILLUSTRATIVE_PURPOSES: 'התמונות באתר זה הם להמחשה בלבד.',
    TO_CHAMPIONS_WEBSITE: 'לאתר צ\'מפיון מוטורס',

    // configurator steps
    MODEL: 'דגם',
    ENGINE_AND_TRIM_LEVEL: 'מנוע ורמת גימור',
    EQUIPMENT_PACKAGES_AND_ADDITIONAL_OPTIONS: 'אבזור ותוספות',
    EQUIPMENT_PACKAGES: 'חבילות אבזור',
    COLOR: 'צבע',
    HOOPS: 'חישוקים',
    INTERNAL_COLOR: 'צבע פנים',
    ADD_ONS: 'תוספות',
    SUMMARY: 'סיכום',

    // Configurator footer
    MODEL_CODE: 'קוד דגם',
    MODEL_DESCRIPTION: 'תיאור הדגם',
    SAFETY_EQUIPMENT_LEVEL: 'רמת האבזור הבטיחותי',
    AVERAGE_FUEL_CONSUMPTION_PER_LITER: 'צריכת דלק ממוצעת בליטרים ל-100 ק"מ*',
    ELECTRIC_AND_FUEL_CONSUMPTION_DATA: 'נתוני צריכת דלק וחשמל*',
    ELECTRIC_CONSUMPTION_DATA: 'נתוני צריכת חשמל*',
    FUEL_CONSUMPTION_COMBINED_PER_LITER: 'צריכת דלק בנסיעה משולבת משוקללת (ליטר/100 ק"מ)',
    ELECTRIC_CONSUMPTION_PER_KM: 'צריכת חשמל (וואט שעה/ק"מ)',
    ELECTRIC_RANGE: 'טווח נסיעה חשמלית (ק"מ)',
    LEGEND: 'מקרא',
    SYSTEM_IS_INSTALLED: 'מערכת מותקנת בדגם הרכב',
    SYSTEM_IS_NOT_INSTALLED: 'מערכת שאינה מותקנת בדגם הרכב',
    SYSTEM_IS_OPTIONAL: 'מערכת אופציונאלית להתקנה',
    LOW_SAFETY_LEVEL: 'רמת בטיחות נמוכה',
    HIGH_SAFETY_LEVEL: 'רמת בטיחות גבוהה',
    MAX_POLLUTION: 'זיהום מירבי',
    MIN_POLLUTION: 'זיהום מזערי',
    SAFETY_SYSTEMS_DETAILS_AT_TRIM_LEVEL: 'פירוט מערכות הבטיחות המותקנות ב %1% ברמת גימור %2%',
    POLLUTION_DISCLAIMER: 'דגם הרכב עומד בפני התחלת ייצור, לפיכך טרם קיבל את האישורים המתאימים במסגרת הליך התקינה האירופאית וטרם קיימים לגביו נתונים מהימנים על צריכת הדלק ופליטת מזהמי האוויר',
    AIR_POLLUTION_LEVEL_FROM_MOTOR_VEHICLE: 'דרגת זיהום אוויר מרכב מנועי',
    MANUFACTURER_DATA_ACCORDING_TO_LAB_TEST: 'נתוני היצרן ע"פ בדיקת מעבדה, תקן',
    THERE_MAY_BE_SOME_GAP_BETWEEN_FUEL_CONSUMPTION: 'ייתכן פער בין צריכת הדלק בתנאי מעבדה לבין צריכת הדלק בפועל',
    RANK_IS_BEING_CALCULATED_ACCORDING_TO: 'הדרגה מחושבת לפי תקנות אוויר נקי (גילוי נתוני זיהום אוויר ברכב מנועי בפרסומות) התשס"ט 2009',
    FOR_COMPARISON_BETWEEN_TRIM_LEVELS: 'להשוואה בין רמות גימור',
    CONFIGURATOR_DISCLAIMER: 'כמות הרכבים המוצעת באתר מוגבלת. השלמת ההזמנה כרוכה בתשלום המקדמה וחתימה על הסכם ההזמנה. ייתכן הבדל בין גוון הצבע הנראה כאן לבין גוון הרכב בפועל. ניתן להתרשם מהרכב באולמות התצוגה של החברה. התיאור, המידע, הנתונים והתמונות המובאים כאן, נכונים ליום פרסומם ויתכנו בהם שינויים, לרבות שינויים הנעשים על ידי היבואן ו/או היצרן מעת לעת, בין היתר לצורך ייבוא הרכבים והתאמתם לישראל. בכל מקרה של סתירה בין האמור באתר זה לבין תנאי הסכם ההזמנה, יגברו תנאי הסכם ההזמנה. ביצוע ההזמנה באתר מהווה הסכמה לתנאי הסכם ההזמנה המובאים באתר זה.',
    CONFIGURATOR_DISCLAIMER_VWC: 'כמות הרכבים המוצעת באתר מוגבלת. השלמת ההזמנה כרוכה בתשלום המקדמה וחתימה על הסכם ההזמנה. ייתכן הבדל בין גוון הצבע הנראה כאן לבין גוון הרכב בפועל. ניתן להתרשם מהרכב באולמות תצוגה הבאים: \nאולם תצוגה פולקסווגן, ראשל״צ\nאולם תצוגה פולקסווגן, ירושלים\nאולם תצוגה פולקסווגן, סוכנות דור, נצרת\nהתיאור, המידע, הנתונים והתמונות המובאים כאן, נכונים ליום פרסומם ויתכנו בהם שינויים, לרבות שינויים הנעשים על ידי היבואן ו/או היצרן מעת לעת, בין היתר לצורך ייבוא הרכבים והתאמתם לישראל. בכל מקרה של סתירה בין האמור באתר זה לבין תנאי הסכם ההזמנה, יגברו תנאי הסכם ההזמנה. ביצוע ההזמנה באתר מהווה הסכמה לתנאי הסכם ההזמנה המובאים באתר זה.',
    COLORS_DISCLAIMER: 'מבין צבעי הרכב הזמינים Online ממלאי הרכבים של החברה. ייתכן הבדל בין גוון הצבע הנראה במסך לבין הגוון בפועל.',
    INVOICE_DISCAIMER: 'לתשומת לבך הפרטים אשר ימסרו על ידך לצורך רישום באתר ישמשו לצורך הנפקת החשבונית עבור הרכישה',
    YOU_CAN_BE_IMPRESSED: 'ניתן להתרשם מדגם הרכב (גם אם ברמת גימור שונה) גם באולמות תצוגה שכתובתם מצוינת ברשימת אולמות התצוגה',
    ERRMSG_NOT_SUCCESS: 'השלמת העסקה נכשלה',
    NO_DATA_TO_DISPLAY: 'אין נתונים להצגה',

    // Slider
    NO_IMAGES_TO_DISPLAY: 'אין תמונות להצגה',

    // CONTACT US
    FULL_NAME: 'שם מלא',
    EMAIL_ADDRESS: 'כתובת דוא"ל',
    PHONE: 'טלפון',
    THIS_IS_REQUIRED_FIELD: 'שדה זה הוא חובה.',
    PLEASE_FILL_OUT_YOUR_EMAIL: 'יש למלא כתובת דוא"ל',
    EMAIL_FIELD_MUST_BE_VALID: 'השדה מייל חייב להכיל כתובת אימייל תקינה',
    PHONE_NUM_FIELD_MUST_BE_VALID_AND_10_CHARACTERS: 'השדה נייד חייב להיות מספר, להתחיל ב 05 ולהכיל 10 ספרות בדיוק',
    CUSTOMER_SERVICE: 'שירות לקוחות',
    TECHNICAL_PROBLEM: 'בעיה טכנית',
    CAR_FINANCING_AND_INSURANCE: 'מימון וביטוח רכב',
    ORDER: 'הזמנת רכב חדש',
    REQUESTED_SUBJECT: 'נושא הפניה',
    APPLYING_CONTENT: 'תוכן הפניה',
    SUBMIT_APPLICATION: 'שלח פניה',
    SEND: 'שלח',
    CONTACT_ME_BY_PHONE: 'צרו איתי קשר טלפוני',
    THANK_YOU_WE_WILL_CONTACT_YOU_SOON: 'תודה רבה, ניצור עמך קשר בהקדם',
    
    // Specifications Tabs
    EQUIPMENT: 'אבזור',
    ENGINE: 'מנוע',
    MEASUREMENTS: 'מידות',
    TECHNICAL_ENGINE_DATA: 'נתונים טכניים למנוע',
    NO_SPECIFICATIONS_TO_DISPLAY: 'אין מפרט להצגה',
    OPERATIONAL_EQUIPMENT: 'אביזרי תפעול',
    MULTIMEDIA_AND_CONNECTIVITY: 'מולטימדיה וקישוריות',
    DESIGN: 'עיצוב',
    
    // Checkout
    IDENTIFICATION_AND_REGISTRATION: 'הזדהות והרשמה',
    PICK_UP_AND_FINANCING: 'איסוף ומימון',
    PAYMENT_AND_DOWN_PAYMENT: 'תשלום ומקדמה',
    IDENTIFICATION: 'הזדהות',
    REGISTRATION: 'הרשמה',
    HELLO_HAVE_WE_MET: 'שלום לך, אנחנו כבר מכירים?',
    FILL_OUT_YOUR_ID_FOR_IDENTIFICATION: 'הזן את מספר תעודת הזהות שלך כדי שנוכל לזהות אותך או להעביר אותך להמשך ההרשמה',
    ID_NUMBER: 'מספר ת.ז',
    ID_NUMBER_IS_NOT_CORRECT_PLEASE_TRY_AGAIN: 'מספר תעודת הזהות אינו תקין, נסה שוב',
    SMS_CODE_INPUT_MUST_BE_NUMERIC: 'השדה sms_code חייב להיות מספרי',
    NICE_TO_MEET_YOU_WE_ARE: (brandName) => `נעים מאוד, אנחנו ${brandName}`,
    THIS_PART_IS_ALL_ABOUT_YOU: 'החלק הזה הוא עליך, זה די חשוב שהפרטים יהיו מדויקים, מאחר שאלו הפרטים שיירשמו ברישיון הרכב החדש שלך.',
    FAILED_TO_IDENT: 'הזיהוי לא צלח, כעת נעבור לתהליך רישום קצר',
    FIRST_NAME: 'שם פרטי',
    LAST_NAME: 'שם משפחה',
    MOBILE_PHONE: 'טלפון נייד',
    MUST_BE_FILLED_OUT: 'יש למלא',
    NAME_OF_THE_BANK_ACCOUNT_OWNER_ON_WHICH_THE_VEHICLE_WILL_BE_REGISTERED: 'שם בעל חשבון הבנק עליו יירשם הרכב',
    ENTER_VALID_EMAIL_ADDRESS_FOR_EXAMPLE: 'הקלד כתובת אימייל תקינה, לדוגמא: johndoe@domain.com',
    IN_ORDER_TO_PROCEED_TO_THE_NEXT_STEP_TERMS_OF_USE: 'כדי להתקדם לשלב הבא עלייך לאשר את תנאי השימוש',
    CHECKOUT_REG_PROVIDING_DETAILS_DISCLAIMER_1: 'הפרטים שמסרת עשויים לשמש את צ\'מפיון מוטורס בע״‬מ לשליחת דיוור ומידע פרסומי באמצעות דוא״‬ל ו/או מסרונים.',
    CHECKOUT_REG_PROVIDING_DETAILS_DISCLAIMER_2: 'אני מאשר/ת לקבל מידע שיווקי מצ\'מפיון מוטורס בע״‬מ כאמור לעיל.',
    I_APPROVE_THE_FOLLOWING: 'אני מאשר/ת את',
    TERMS_OF_USE: 'תנאי השימוש',
    ID: 'תעודת זהות',
    CONTINUE_TO_PICK_UP_AND_FINANCING: 'המשך לאיסוף ומימון',
    CONTINUE_TO_PAYMENT_AND_DOWN_PAYMENT: 'המשך לתשלום מקדמה',
    PLEASE_MAKE_SURE_THAT_THE_DETAILS_ARE_CORRECT: 'לתשומת לבך: יש לוודא כי הפרטים שמולאו בטופס זה נכונים ותקינים',
    PICK_UP: 'קבלת הרכב',
    FINANCING: 'מימון',
    HERE_IT_COMES: 'הנה זה בא...',
    CHOOSE_YOUR_SHOW_ROOM: 'אנא בחר את מתחם קבלת הרכב',
    VEHICLE_DELIVERING_WILL_BE_DONE_WITHIN_15_DAYS: 'הרכב יימסר לידי המזמין באתר המסירה שיתואם עימו עד 15 ימי עבודה (למעט ימי ו\' וערבי חג) מעת שחרור הרכב מהמכס וכפוף לביצוע מלוא התשלום.',
    ONE_SECOND_BEFORE_WE_CONTINUE: 'שניה לפני שממשיכים',
    WE_WHOULD_LIKE_TO_KNOW_IF_YOU_NEED_FINANCING: 'נשמח לדעת האם תרצה לשלם את יתרת התשלום באמצעות מימון מחברת מימון או מהבנק, או בהעברה בנקאית?',
    I_WHOULD_LIKE_TO_GET_A_FINANCING: 'אני מעוניין באפשרות מימון על יתרת התשלום עבור הרכב',
    FINANCING_DISCLAIMER: 'צ\'מפיון מוטורס אינה גוף מממן. המימון מוצע ע"י גוף מימון וכרוך בחתימה על הסכם הלוואה ועמלת פתיחה בכפוף לתנאי ולשיקול דעתו ואחריותו הבלעדית של הגוף המממן.',
    CONTACT_US_FOR_TRADE_IN: 'שים לב, במידה והינך מעוניין בטרייד אין, יש ליצור קשר עם נציג המכירות: %1%',
    PAYMENT: 'תשלום',
    LIKE_WE_SAID_THE_DOWN_PAYMENT_IS: 'כמו שאמרנו, 2000 ₪ שישמשו כתשלום מקדמה לשריון הרכב על שמך. העסקה מאובטחת, אז אין לך מה לדאוג.',
    I_HAVE_AN_OTHER_PHONE_NUMBER: 'יש לי מספר טלפון אחר,',
    I_WHOULD_LIKE_TO_UPDATE: 'אני מעוניין לעדכן',
    WE_HAVE_RECEIVED_YOU_ORDER_WHAT_AN_EXCITEMENT: 'ההזמנה שלך התקבלה איזו התרגשות!',
    PAYMENT_SUCCESS_OUR_REPRESENTATIVE_WILL_CONTACT_YOU_SHORTLY: 'במהלך שני ימי העסקים הבאים ניצור עמך קשר ונעביר לך מספר מסמכים והנחיות להמשך התהליך באמצעות הדואר האלקטרוני, תוכל לקבל את כל התשובות לשאלות שלך. מזל טוב!',
    PAYMENT_SUCCESS_DISCLAIMER: `
לתשומת ליבך, אנו מתחילים בהכנת הרכב למסירה. על כן, עומדים לרשותך 7 ימים לשלם את יתרת התשלום עבור הרכב. מסירת הרכב תתבצע באולם התצוגה ממנו בחרת לאסוף את הרכב.
    `,
    TAKE_ME_TO_MY_PERSONAL_AREA: ' קח אותי לאזור האישי שלי',
    ANSWERS_TO_ALL_OF_YOUR_QUESTIONS: 'כל התשובות לשאלות שלך',
    WANT_TO_SPEAK_WITH_US: 'רוצה לדבר איתנו?',

    // EquipmentPackages Step/Screen
    CHOOSE_EQUIPMENT_PACKAGE: 'בחרו חבילת אבזור',
    THERE_IS_NO_INFORMATION_TO_SHOW: 'אין מידע להצגה',
    HIGHLIGHTS: 'היילייטס',
    NO_EQUIPMENT_PACKAGES_ARE_AVAILABLE_TO_DISPLAY: 'אין חבילות אבזור זמינות להצגה',
    EXTERNAL_COLORS_TO_BE_CHOSEN: 'צבעי חוץ',
    EXTERNAL_COLOR_TO_BE_CHOSEN: 'צבע חוץ',
    INTERNALS_COLORS_TO_BE_CHOSEN: 'צבעי פנים',
    INTERNAL_COLOR_TO_BE_CHOSEN: 'צבע פנים',
    FOR_FURTHER_INFORMATION_REGARDING_THIS_MODEL: 'למידע נוסף על הדגם',

    THIS_COLOR_IS_NOT_INCLUDED_IN_THE_MODEL_YOU_CHOSE: 'צבע זה אינו כלול בדגם שבחרת',
    THESE_HOOPS_ARE_NOT_INCLUDED_IN_THE_CHOSEN_EQUIPMENT_PACKAGE: 'חישוקים אלו אינם נכללים בחבילת האבזור שבחרת',

    // How does it work Screen
    HOW_EASY_TO_BUY: (brandName) => `אז כמה זה קל להזמין ${brandName} חדשה בלי לקום מהספה?`,
    ORDER_IN_FOUR_STEPS: (brandName) => `את ה${brandName} שלך מזמינים ב-4 שלבים פשוטים`,
    STEP_ONE_BUILD_AND_PICK: 'שלב 1 - בונים ובוחרים',
    STEP_ONE_BUILD_AND_PICK_CONTENT: 'מאפיינים את הרכב שמתאים לכם. בוחרים את הצבע, הגימור הפנימי ותוספות רצויות',
    STEP_TWO_REGISTER: 'שלב 2 - מכירים ונרשמים',
    STEP_TWO_REGISTER_CONTENT: 'ממלאים פרטים ונרשמים. אתם יודעים, כדי שתוכלו להתעדכן בכל הפרטים',
    STEP_THREE_PAYMENT: 'שלב 3 - משלמים מקדמה',
    STEP_THREE_PAYMENT_CONTENT: 'בוחרים את האולם הקרוב אליכם, את אופן התשלום הנוח לכם ומשלמים מקדמה על הרכב',
    STEP_FOUR_FINISH: 'שלב 4 - מסיימים ומתרגשים',
    STEP_FOUR_FINISH_CONTENT: 'מסדירים את יתרת התשלום, מעבירים טפסים, וזהו תתחדשו!',

    // Audi
    FILTER_BY_MODEL: 'לפי דגם',
    FILTER_BY_KIND: 'לפי סוג',
    BUILD_THE_MODEL: 'בחר',
    HOOPS_TO_BE_CHOSEN: 'חישוקי סגסוגת',
    READ_MORE: 'קרא עוד',
    SHOW_MORE: 'הצג עוד',
    READ_LESS: 'סגור',
    FOOTER_PRICE_INCLUDES_VAR_AND_LICENSING_FEE: 'מחיר כולל מע"מ ואגרת רישוי',
    BACK: 'חזרה',
    BACK_TO_ORDER: 'חזרה לעמוד ההזמנה',
    YOUR_CHOICE: 'הבחירה שלך',
    AUDI_PHONE: 'נייד',
    AUDI_EMAIL_ADDRESS: 'דוא"ל',
    ERROR_ORDER_NOT_ACCEPTED: 'ההזמנה לא נקלטה!',
    THERE_WAS_AN_ERROR: 'אירעה שגיאה במהלך סליקת כרטיס האשראי שלך.',
    DONT_WORRY: 'אל דאגה - פרטי הזמנתך נשמרו ואנו ניצור עמך קשר במהלך שני ימי העסקים הבאים על מנת להשלים אותה.',
    YOUR_CONTACT_INFO: 'פרטי ההתקשרות שלך השמורים אצלנו הנם:',
    YOU_CAN_CONTACT_US_VIA: 'ניתן ליצור עמנו קשר בנוגע להזמנתך באמצעות אחת מן הדרכים הבאות:',
    EMAIL: 'דואר אלקטרוני',
    ADDRESS: 'כתובת',
    LEAVE_DETAILS_FOR_AUDI_REPRESENTATIVE: 'השאירו פרטים ונציג Audi יצור אתכם קשר',
    AUDI_YOUR_ORDER_IS_RECEIVED: 'ההזמנה שלך התקבלה',
    AUDI_WHAT_AN_EXCITEMENT: 'איזו התרגשות',
    AUDI_PAYMENT_SUCCESS_OUR_REPRESENTATIVE_WILL_CONTACT_YOU_SHORTLY: 'במהלך שני ימי העסקים הבאים ניצור עמך קשר ונעביר לך מספר מסמכים והנחיות להמשך התהליך באמצעות הדואר האלקטרוני.',
    AUDI_CONGRATULATIONS: 'ברכות',
    AUDI_PAYMENT_SUCCESS_DISCLAIMER: 'לתשומת לבך, אנו מתחילים בהכנת הרכב למסירה. על כן, עומדים לרשותך 7 ימים לשלם את יתרת התשלום עבור הרכב. מסירת הרכב תתבצע באולם התצוגה ממנו בחרת לאסוף את הרכב.',
    NIS_IN_HEBREW: 'ש"ח',
    PICK: 'בחירה',
    CONTINUE_TO_PICK: 'המשך לבחירת ',
    EXTERNAL_COLORS: 'צבעי חוץ',
    INTERNAL_COLORS: 'צבעי פנים',
    HOOPS_KIND: 'סוגי חישוקים',
    PAYMENT_TITLE_MODEL_WITH_TAXES: 'מחיר הדגם כולל מע"מ',
    AUDI_FILL_OUT_YOUR_ID_FOR_IDENTIFICATION: 'אנא הזן את מספר תעודת הזהות שלך כדי שנוכל לזהות אותך או להעביר אותך לתהליך רישום קצר',
    AUDI_SEND_IT_AGAIN: 'שילחו לי אותו פעם נוספת',
    AUDI_I_WHOULD_LIKE_TO_UPDATE: 'ארצה לעדכן אותו',
    AUDI_PICK_UP: 'קבלת הרכב',
    AUDI_CHOOSE_YOUR_SHOW_ROOM: 'אנא בחר את מתחם קבלת הרכב',
    AUDI_CHOOSE_DELIVERY_COMPLEX: 'קבלת הרכב',
    AUDI_FINANCING: 'יתרת התשלום',
    AUDI_WE_WHOULD_LIKE_TO_KNOW_IF_YOU_NEED_FINANCING: 'נשמח לדעת באיזה אופן תרצה לשלם את יתרת התשלום - האם באמצעות מימון מחברת מימון/הבנק, או באמצעות העברה בנקאית?',
    AUDI_FINANCING_DISCLAIMER_AND_TRADE_IN: 'צ\'מפיון מוטורס אינו גוף מממן. המימון יתבצע ע\'\'י בנק ו/או גופי מימון המתמחים בכך. כל החישובים הינם סימולציות בלבד. שים לב, במידה והינך מעוניין בטרייד אין, יש ליצור קשר עם נציג המכירות: %1%.',
    AUDI_PAYMENT_MESSAGE_1: 'כאמור, ',
    AUDI_PAYMENT_MESSAGE_2: 'ישמשו כתשלום מקדמה בעסקה מאובטחת, לטובת שריון הרכב על שמך.',
    AUDI_SUCCESS_MESSAGE_SENT: 'הודעת אישור נשלחה לכתובת הדואר האלקטרוני שציינת, ',
    ORDER_ID: 'מספר הזמנה: ',
    BUYING_PROCESS: 'תהליך הרכישה',
    MAKE_CONTACT: 'יצירת קשר',
    AUDI_THANK_YOU_WE_WILL_CONTACT_YOU_SOON: 'פרטיך התקבלו בהצלחה, ניצור עמך קשר בהקדם',
    AUDI_LAST_DETAILS: 'פרטים אחרונים להשלמת ההזמנה',
    AUDI_HAVE_YOU_RECEIVED_AN_SMS_CODE: 'קבלת קוד במסרון',
    AUDI_ONE_SECOND_BEFORE_WE_CONTINUE: 'לפני שממשיכים',
    AUDI_ACCEPT_ADVERTISING: 'בחתימתי מטה הנני מסכים לקבלת מצ\'מפיון מוטורס בע"מ ו/או מאוטו דיל מכירות רכב 1997 בע"מ ו/או גורמים קשורים, כפי שיהיו מעת לעת, דיוור לצרכים שיווקיים ופרסומיים בכל אמצעי התקשורת לרבות דוא"ל ו/או נייד. זאת כל עוד לא נתקבלה הודעה אחרת ממני בכל עת, בכתב או בכל דרך אחרת שתפורט בעת משלוח הדיוור. מובהר כי הסכמה לסעיף זה אינה מהווה תנאי להתקשרות בהסכם המכר.',
    AUDI_NAME_OF_THE_BANK_ACCOUNT_OWNER_ON_WHICH_THE_VEHICLE_WILL_BE_REGISTERED: 'שם בעל חשבון הבנק',

    // Audi configurator steps
    AUDI: {
        MODEL: 'דגם',
        ENGINE_AND_TRIM_LEVEL: 'מנוע ורמת גימור',
        EQUIPMENT_PACKAGES: 'חבילות אבזור',
        COLOR: 'צבע הרכב',
        HOOPS: 'חישוקים',
        INTERNAL_COLOR: 'צבע פנים',
        ADD_ONS: 'תוספות',
        SUMMARY: 'הבחירה שלך',
    },

    CHOOSING_A_CONFIGURATION: 'בחירת תצורה',
    FOR_YOUR_CHOICE_PICK_ADDITIONAL_ITEM: 'רמת הגימור מורכבת מחבילות אבזור, תוספות המגיעות מובנות עם הרכב, צבעים זמינים ועוד... כאן תוכלו לבצע השוואת מחירים ולבחור את רמת הגימור המתאימה לכם    ',
    FOR_YOUR_ATTENTION_LEGAL_TEXT: 'לתשומת לבך: המחיר המצוין עשוי להשתנות בין מועד ההזמנה לבין מועד מסירת הרכב כמפורט בהסכם ההזמנה',
    I_READ_AND_ACCEPT: 'קראתי ואישרתי את תנאי',
    ORDER_AGREEMENT: 'הסכם ההזמנה',
    MUST_FILL_YOU_READ_ORDER_AGREEMENT: 'עליך לסמן שקראת את הסכם ההזמנה, על מנת להמשיך בתהליך',
    SPECS_AND_MAIN_CHARACTISTICS: 'אבזור ומאפיינים עיקריים',
    LAST_DETAILS_VWC: 'פרטים אחרונים ותשלום מקדמה',
    MUST_FILL_EMAIL: 'המערכת זיהתה שחסרה כתובת דואר אלקטרוני, יש להכניס את כתובת המייל כדי שנוכל לשלוח את פרטי ההזמנה בסיום התהליך',
    SITE_IS_UNDER_MAINTENANCE: 'האתר עובר פעולות שדרוג ותחזוקה,',
    SORRY_FOR_INCONVENIENCE: 'עמכם הסליחה.',
    WE_WILL_BE_BACK_SOON: 'בקרוב נחזור לפעילות מלאה.',
    COLORS: 'צבעים',
    WITHOUT_EQUIPMENT_PACKAGE: 'ללא חבילת אבזור',
};

export default HEBREW;
