import {
    CONFIGURATOR_STEPS, MENUFACTURS, CHAMPION_MEDIA_URL, SIZES,
} from './constants';
import ICONS from './assets';
import STRINGS from './language/hebrew';
import { getMutagLogo, getMutagSocialLink } from './utils/assetsUtils';
import { getManufacturerWebsite } from './utils/commonUtils';

const {
    SOCIAL_FACEBOOK,
    SOCIAL_INSTAGRAM,
    SOCIAL_YOUTUBE,
    SOCIAL_TIKTOK,
    SOCIAL_FACEBOOK_DARK,
    SOCIAL_INSTAGRAM_DARK,
    SOCIAL_YOUTUBE_DARK,
    SOCIAL_TIKTOK_DARK,
} = ICONS;

const FACEBOOK = 'FACEBOOK';
const INSTAGRAM = 'INSTAGRAM';
const YOUTUBE = 'YOUTUBE';
const TIKTOK = 'TIKTOK';

const { REACT_APP_BASE_URL } = process.env;

const CONFIG = {
    VERSION: '6.3',
    BASE_URL: REACT_APP_BASE_URL,
    LOADER: {
        MESSAGE_TIMEOUT: 1000,
        SIZE: 52,
    },
 
    CHAMPION_MEDIA_URL,
    SUCCESS_CONFETI_DURATION: 3000,

    DEVICE_SIZE: {
        VERY_SMALL: 380,
        SMALL: 576,
        MEDIUM: 768,
        LARGE: 992,
        VERY_LARGE: 1200,
        EXTRA_LARGE: 1400,
    },
    
    CREDITS: [
        { href: 'https://spinframe.com/', title: '360 - Spinframe Technologies' },
    ],

    STEPS: [
        { id: CONFIGURATOR_STEPS.MODEL, name: STRINGS.MODEL },
        { id: CONFIGURATOR_STEPS.ENGINE_AND_TRIM_LEVEL, name: STRINGS.ENGINE_AND_TRIM_LEVEL },
        { id: CONFIGURATOR_STEPS.EQUIPMENT_PACKAGES, name: STRINGS.EQUIPMENT_PACKAGES_AND_ADDITIONAL_OPTIONS },
        { id: CONFIGURATOR_STEPS.COLOR, name: STRINGS.COLOR },
        { id: CONFIGURATOR_STEPS.INTERNAL_COLOR, name: STRINGS.INTERNAL_COLOR },
        { id: CONFIGURATOR_STEPS.HOOPS, name: STRINGS.HOOPS },
        { id: CONFIGURATOR_STEPS.ADD_ONS, name: STRINGS.ADD_ONS },
        { id: CONFIGURATOR_STEPS.SUMMARY, name: STRINGS.SUMMARY },
    ],

    SOCIAL_LINKS: (brand) => {
        let facebookIcon = SOCIAL_FACEBOOK;

        let youtubeIcon = SOCIAL_YOUTUBE;

        let instagramIcon = SOCIAL_INSTAGRAM;

        let tiktokIcon = SOCIAL_TIKTOK;

        if ([MENUFACTURS.CUPRA, MENUFACTURS.AUDI].includes(brand)) {
            facebookIcon = SOCIAL_FACEBOOK_DARK;
            youtubeIcon = SOCIAL_YOUTUBE_DARK;
            instagramIcon = SOCIAL_INSTAGRAM_DARK;
            tiktokIcon = SOCIAL_TIKTOK_DARK;
        }
        
        return [
            { icon: getMutagLogo(brand, SIZES.SMALL), link: getManufacturerWebsite(brand) },
            { icon: youtubeIcon, link: getMutagSocialLink(brand, YOUTUBE) },
            { icon: facebookIcon, link: getMutagSocialLink(brand, FACEBOOK) },
            { icon: instagramIcon, link: getMutagSocialLink(brand, INSTAGRAM) },
            { icon: tiktokIcon, link: getMutagSocialLink(brand, TIKTOK) },
        ];
    },

    brand: process.env.REACT_APP_BRAND.toUpperCase(),
    subBrand: process.env.REACT_APP_SUB_BRAND?.toUpperCase(),

    LEADS: {
        SENDER_ID: process.env.REACT_APP_SENDER_ID,
        CAMPAIGN_ID: process.env.REACT_APP_CAMPAIGN_ID,
        SENDER_NAME: process.env.REACT_APP_SENDER_NAME,
    },

};

export default CONFIG;
