import React from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../../language';
import { COLORS } from '../../../../../constants';
import ColorsRangeRuler from '../../../../../components/ColorsRangeRuler/ColorsRangeRuler';
import * as Styled from './PollutionRange.styles';

const PollutionRange = ({ regulation, pollutionLevel, isManyModels }) => {
    const colors = Object.values(COLORS.BG_COLORS_POLLUTION_LEVEL);
    
    return (
        <Styled.PollutionRange>
            <Styled.Title>{STRINGS.AIR_POLLUTION_LEVEL_FROM_MOTOR_VEHICLE}**</Styled.Title>
            <ColorsRangeRuler
                itemFontColor={ COLORS.COLOR_TEXT_POLLUTION_ITEM }
                colors={ colors }
                isManyModels={ isManyModels }
                selectedColorNumber={ parseInt(pollutionLevel) }
                firstColorNumberIndex={ 1 }
                lowRangeTitle={ STRINGS.MIN_POLLUTION }
                highRangeTitle={ STRINGS.MAX_POLLUTION }
            />
            <Styled.Footer>
                <Styled.Text>
                    {`*${STRINGS.MANUFACTURER_DATA_ACCORDING_TO_LAB_TEST}: `}
                    <Styled.RegulationText>{regulation}</Styled.RegulationText>
                </Styled.Text>
                <Styled.Text>
                    {STRINGS.THERE_MAY_BE_SOME_GAP_BETWEEN_FUEL_CONSUMPTION}
                </Styled.Text>
                <Styled.Text>
                    **{STRINGS.RANK_IS_BEING_CALCULATED_ACCORDING_TO}
                </Styled.Text>
            </Styled.Footer>
        </Styled.PollutionRange>
    );
};

PollutionRange.defaultProps = {
    isManyModels: false,
};

PollutionRange.propTypes = {
    regulation: PropTypes.string.isRequired,
    pollutionLevel: PropTypes.array.isRequired,
    isManyModels: PropTypes.bool,
};

export default PollutionRange;
